<template>
  <b-card>
    <template #header>
      <unicon name="users-alt" class="mr-1" style="fill: #6e6b7b"></unicon>
      <h4 class="m-0">معلومات المستخدم</h4>
    </template>
    <ValidationObserver ref="form">
      <b-row class="mt-2">
        <b-col lg="4" md="6">
          <ek-input-text
            :rules="[{ type: 'required', message: 'اسم المستخدم مطلوب' }]"
            v-model="dtoDetailsDashUser.name"
            name="الاسم الكامل"
            label="الاسم الكامل"
          ></ek-input-text>
        </b-col>
        <b-col lg="4" md="6">
          <ek-input-text
            :rules="[
              { type: 'required', message: 'الاسم مطلوب' },
              {
                type: 'email',
                message: 'يرجى ادخال بريد الكتروني صحيح',
              },
            ]"
            v-model="dtoDetailsDashUser.email"
            name=" بريد الالكتروني"
            label="بريد الالكتروني"
          ></ek-input-text>
        </b-col>
        <b-col lg="4" md="6">
          <ek-input-text
            :rules="[
              { type: 'required', message: 'رقم مطلوب' },
              { type: 'mobile', message: 'رقم الهاتف غير صالح' },
            ]"
            v-model="dtoDetailsDashUser.phoneNumber"
            name="رقم الهاتف"
            label="رقم الهاتف"
          ></ek-input-text>
        </b-col>
        <b-col lg="4" md="6">
          <ek-date-picker
            :rules="[{ type: 'required', message: 'تاريخ الميلاد مطلوب' }]"
            v-model="dtoDetailsDashUser.birthDate"
            name="تاريخ الميلاد"
            label="تاريخ الميلاد"
          ></ek-date-picker>
        </b-col>
        <b-col lg="4" md="6">
          <ek-date-picker
            :rules="[{ type: 'required', message: 'تاريخ الحساب مطلوب' }]"
            v-model="dtoDetailsDashUser.registrationDate"
            name="  تاريخ التسجيل"
            label=" تاريخ التسجيل"
          ></ek-date-picker>
        </b-col>
        <b-col lg="4" md="6">
          <ek-input-select
            :options="dashUserRole"
            v-model="dtoDetailsDashUser.role"
            name=" الصلاحيات"
            label=" الصلاحيات"
          ></ek-input-select>
        </b-col>
        <b-col lg="4" md="6">
          <ek-input-text
            v-model="dtoDetailsDashUser.newPassword"
            name=" كلمة السر الجديدة"
            label=" كلمة السر الجديدة"
          ></ek-input-text>
        </b-col>
      </b-row>
    </ValidationObserver>
    <template #footer>
      <div class="d-flex justify-content-between">
        <div>
          <b-button variant="main-color" class="mr-1" @click="submit()">تعديل</b-button>
          <b-button variant="outline-primary" @click="backBtn">تراجع</b-button>
        </div>
        <div>
          <b-button @click="setIsBlock" variant="outline-danger" class="mr-1">{{
            dtoDetailsDashUser.isBlocked ? "الغاء الحظر" : "حظر"
          }}</b-button>
          <b-button @click="deleteUser(id)" variant="outline-primary">حذف</b-button>
        </div>
      </div>
    </template>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["id"],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      dtoDetailsDashUser: (state) => state.dashboardUsers.dtoDetailsDashUser,
      dashUserRole: (state) => state.dashboardUsers.dashUserRole,
      dashUserList: (state) => state.dashboardUsers.dashUserList,
    }),
  },
  methods: {
    sendIdToAction(id) {
      this.getDashUserById(id);
    },
    ...mapActions([
      "getDashUserById",
      "updateDashUser",
      "deleteDashUser",
      "blockDashUser",
      "unBlockDashUser",
    ]),
    submit() {
      this.$refs["form"].validate().then((suc) => {
        if (suc)
          this.updateDashUser({
            id: this.dtoDetailsDashUser.id,
            name: this.dtoDetailsDashUser.name,
            email: this.dtoDetailsDashUser.email,
            phoneNumber: this.dtoDetailsDashUser.phoneNumber,
            birthDate: this.dtoDetailsDashUser.birthDate,
            newPassword: this.dtoDetailsDashUser.newPassword,
            role: this.dtoDetailsDashUser.role,
          });
      });
    },
    backBtn() {
      this.$router.push("/admin/dashboardUsers");
    },
    deleteUser(id) {
      this.deleteDashUser(id);
    },
    setIsBlock() {
      if (!this.dtoDetailsDashUser.isBlocked) {
        this.blockDashUser(this.id);
      } else {
        this.unBlockDashUser(this.id);
      }
    },
  },
  created() {
    this.sendIdToAction(this.id);
  },
  beforeDestroy() {
    this.$store.commit("Reset_User_Dto");
  },
};
</script>

<style lang="scss">
.card-header {
  display: flex;
  justify-content: start !important;
  align-items: center;
}
.card-footer {
  border: none !important;
}
</style>
